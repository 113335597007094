<template>
  <div class="profile-container">
    <div class="profile-container-header">
      <span class="title">告警信息</span>
    </div>
    <div class="profile-container-content">
      <el-table :data="tableData" style="width: 100%" v-loading="alarmLoading">
        <el-table-column prop="deviceName" label="设备名称" width="180">
        </el-table-column>
        <el-table-column prop="deviceId" label="设备ID" width="340">
        </el-table-column>
        <el-table-column prop="time" label="告警时间" width="280px">
          <template>
            <span>{{ dateFormat("YYYY-mm-dd HH:MM", new Date()) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="告警内容">
          <template slot-scope="scope">
            <span>{{ scope.row.alarmContent }}</span>
            <span class="reset-bg">需更换</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "../../utils/week";

export default {
  name: "Alarm",
  data() {
    return {
      active: 1,
      tableData: [],
      alarmLoading: false,
      // tableData: [
      //   {
      //     id: "JD123123",
      //     name: "一年级1班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      //   {
      //     id: "JD123124",
      //     name: "一年级2班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      //   {
      //     id: "JD123125",
      //     name: "一年级3班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      //   {
      //     id: "JD123126",
      //     name: "一年级4班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      // ],
    };
  },
  methods: {
    getData() {
      this.alarmLoading = true;
      this.$http
        .getAlarmList(window.localStorage.getItem("assetID"))
        .then((res) => {
          this.tableData = res.data;
          this.alarmLoading = false;
        })
        .catch(() => {
          this.alarmLoading = false;
        });
    },
    dateFormat,
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.profile-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  min-width: 999px;
  .profile-container-header {
    width: 100%;
    display: flex;
    height: 70px;
    padding-left: 40px;
    align-items: center;
    color: #333;
    //span {
    //  width: 90px;
    //  line-height: 50px;
    //  text-align: center;
    //  font-size: 14px;
    //  color: #333;
    //  cursor: pointer;
    //  border-bottom: 1px solid #eaeaea;
    //  &:nth-child(2) {
    //    border-left: 1px solid #eaeaea;
    //    border-right: 1px solid #eaeaea;
    //  }
    //  &:nth-last-child(1) {
    //    flex: 1;
    //  }
    //}
    //.active {
    //  //background-color: #999;
    //  border-bottom: none;
    //  position: relative;
    //  //border-top: 4px solid #01cfa5;
    //  &::before {
    //    content: "";
    //    width: 90px;
    //    height: 4px;
    //    background-color: #01cfa5;
    //    position: absolute;
    //    left: 0;
    //  }
    //}
  }
  .profile-container-content {
    padding-left: 40px;
    //padding-top: 20px;
    display: flex;
    flex-direction: column;
    .reset-bg {
      line-height: 18px;
      display: inline-block;
      font-size: 12px;
      width: 42px;
      height: 18px;
      background-color: #ff6c6c;
      border-radius: 10px;
      color: #fff;
      margin-left: 5px;
      text-align: center;
    }
    .profile-block {
      height: 60px;
      border-bottom: 1px solid #eaeaea;
      font-size: 14px;
      color: #333;
      span {
        &:nth-child(2) {
          margin-left: 30px;
          line-height: 60px;
        }
      }
    }
    ::v-deep .el-table {
      .el-table__row {
        height: 60px;
      }
      .is-leaf {
        background-color: #f4f4f5;
      }
    }
  }
}
</style>
